import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Fuse from 'fuse.js';
import './styles.css'; // Replace with the actual path to your CSS file
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Ranking from './Ranking';
import Search from './Search';
import Questions1 from './Questions1';
import Questions2 from './Questions2';
import EmailField from './EmailField';
import MatchQuestions1 from './MatchQuestions1';
import MatchQuestions2 from './MatchQuestions2';
import MatchResults from './MatchResults';
import About from './About';
import { Helmet } from 'react-helmet';

const App = () => {
  const [barStyle, setBarStyle] = useState({});
  const rankRef = useRef(null);
  const rateRef = useRef(null);
  const matchRef = useRef(null);
  const impressumRef = useRef(null);
  const [showRankMyUni, setShowRankMyUni] = useState(false);
  const [showRanks, setShowRanks] = useState(true);
  const [showUniMatching, setShowUniMatching] = useState(false);
  const [showImpressum, setShowImpressum] = useState(false);
  const [mModuleSelected, setmModuleSelected] = useState(false);

  const topBarRef = useRef(null);
  const mtopBarRef = useRef(null);
  const [topBarHeight, setTopBarHeight] = useState(0);
  const [mtopBarHeight, setmTopBarHeight] = useState(0);
  const [visitorCount, setVisitorCount] = useState(0);

  const [categoriesMatching, setCategoriesMatching] = useState([]);
  const [categoriesMatchingSpecial, setCategoriesMatchingSpecial] = useState([]);
  const [sliderValuesMatching, setSliderValuesMatching] = useState({});

  const [universityData, setUniversityData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const [selectedUniversityData, setSelectedUniversityData] = useState(null);
  // const [universityName, setUniversityName] = useState('');
  // const [universityImageUrl, setUniversityImageUrl] = useState('');
  // const [universityId, setUniversityId] = useState(null);
  const [universitiesRankedOnlyVerified, setUniversitiesRankedOnlyVerified] = useState([]);
  const [universitiesRankedCombined, setUniversitiesRankedCombined] = useState([]);
  const [selectedUniversitiesRanked, setSelectedUniversitiesRanked] = useState([]);
  const [visibleInfoBoxIndex, setVisibleInfoBoxIndex] = useState(null);
  const [fullDetailUniversities, setFullDetailUniversities] = useState(selectedUniversitiesRanked.map(uni => ({
    ...uni,
    showDetails: false // Initially set to false
  })));



  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailWarning, setEmailWarning] = useState('');
  const [showSubmissionError, setShowSubmissionError] = useState(false);
  const [hasSubmittedAVerifiedRanking, setHasSubmittedAVerifiedRanking] = useState(false);


  const [searchInputValue, setSearchInputValue] = useState('');
  const [emailInputValue, setEmailInputValue] = useState('');
  const [searchSuccess, setSearchSuccess] = useState(false); // New state variable
  const [hoveredIndex, setHoveredIndex] = useState(0); // Initialize with -1 to indicate no hover
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsSpecial, setSearchResultsSpecial] = useState([]);
  const [didAlreadySearch, setDidAlreadySearch] = useState(false);
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const [TokenID, setTokenID] = useState("");

  const [universityMatches, setUniversityMatches] = useState([]);

  const [showOnlyVerified, setShowOnlyVerified] = useState(false);
  const [showAllDetailsForAll, setShowAllDetailsForAll] = useState(false);
  const [showOnlyVerifiedForMatching, setShowOnlyVerifiedForMatching] = useState(false);

  const infoButtonRef1 = useRef(null);
  const infoButtonRef2 = useRef(null);


  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isFilterOptionsVisible, setIsFilterOptionsVisible] = useState(false);
  const [isDisplayOptionsVisible, setIsDisplayOptionsVisible] = useState(false);
  const [isMatchingOptionsVisible, setIsMatchingOptionsVisible] = useState(true);


  const [buttonHeight, setButtonHeight] = useState(null);


  const isDebug = false;




  const debugAddress = "http://localhost:5000";
  const productionAddress = "https://api.campusranking.de";
  let address
  if (isDebug) {
    address = debugAddress;
  } else {
    address = productionAddress;
  }



  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };


  // useEffect(() => {
  //   if (rateRef.current) {
  //     const height = rateRef.current.offsetHeight;
  //     setButtonHeight(height);
  //   }
  // }, []); // Empty dependency array ensures this runs only once after the initial render

  // useEffect(() => {
  //   const handleResize = () => {
  //     const height = rateRef.offsetHeight;
  //     setButtonHeight(height);
  //   };

  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  useEffect(() => {

    // Initial update
    updateTopBarHeight();

    // Update on window resize
    window.addEventListener('resize', updateTopBarHeight);

    // Cleanup listener
    return () => window.removeEventListener('resize', updateTopBarHeight);
  }, []);

  const updateTopBarHeight = () => {
    if (topBarRef.current) {
      setTopBarHeight(topBarRef.current.clientHeight);
    }
  };

  useEffect(() => {

    // Initial update
    updatemTopBarHeight();

    // Update on window resize
    window.addEventListener('resize', updatemTopBarHeight);

    // Cleanup listener
    return () => window.removeEventListener('resize', updatemTopBarHeight);
  }, []);

  const updatemTopBarHeight = () => {
    if (mtopBarRef.current) {
      setmTopBarHeight(mtopBarRef.current.clientHeight);
    }
  };

  const [isFeaturingExpanded, setIsFeaturingExpanded] = useState(false);
  const [isImpressumExpanded, setIsImpressumExpanded] = useState(false);
  const [isDatenschutzExpanded, setIsDatenschutzExpanded] = useState(false);

  const toggleImpressum = () => {
    setIsImpressumExpanded(!isImpressumExpanded);
  };
  const toggleFeaturing = () => {
    setIsFeaturingExpanded(!isFeaturingExpanded);
  };

  const toggleDatenschutz = () => {
    setIsDatenschutzExpanded(!isDatenschutzExpanded);
  };



  const handleClickOutsideInfoDiv = (event) => {
    if (infoButtonRef1.current && !infoButtonRef1.current.contains(event.target)) {
      // setVisibleInfoBoxIndex(null); // Hide the info box

      if (infoButtonRef2.current && !infoButtonRef2.current.contains(event.target)) {
        // setVisibleInfoBoxIndex(null); // Hide the info box
        console.log("click away from button");
      } else {
        console.log("click inside button");
      }
    } else {
      console.log("click inside button");
    }

  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideInfoDiv);
    return () => document.removeEventListener('mousedown', handleClickOutsideInfoDiv);
  }, []);

  const toggleUniversityDetails = (index) => {
    setFullDetailUniversities(currentUniversities =>
      currentUniversities.map((uni, uniIndex) =>
        uniIndex === index ? { ...uni, showDetails: !uni.showDetails } : uni
      )
    );
  };

  const toggleVoteRatioDetails = (index) => {
    setVisibleInfoBoxIndex(visibleInfoBoxIndex === index ? null : index);
    console.log("Toggle CLick", index)
  };

  const updateBarPosition = (activeButtonRef) => {

    if (showBar) {
      if (activeButtonRef && activeButtonRef.current) {
        const buttonRect = activeButtonRef.current.getBoundingClientRect();
        const containerRect = activeButtonRef.current.parentNode.getBoundingClientRect();
        setBarStyle({
          left: `${buttonRect.left - containerRect.left}px`,
          width: `${buttonRect.width}px`,
        });
      }
    } else {
      setBarStyle({
        left: `0px`,
        width: `0px`,
      });
    }

  };


  useEffect(() => {
    // Initial position update (for default active button)
    updateBarPosition(rankRef); // Update based on your default active button
  }, []);



  let showBar = true;



  const handleRankMyUniClick = (activeButtonRef) => {
    setShowRankMyUni(true);
    setShowRanks(false);
    setShowUniMatching(false);
    setShowImpressum(false);
    showBar = true;
    updateBarPosition(activeButtonRef);
    window.scrollTo(0, 0);
  };

  const handleShowRanksClick = async (activeButtonRef) => {
    setShowRanks(true);
    setShowRankMyUni(false);
    setShowUniMatching(false);
    setShowImpressum(false);
    showBar = true;
    updateBarPosition(activeButtonRef)
    window.scrollTo(0, 0);
    await fetchUniversities(); // Also call it here when the button is clicked
  };

  
  const handleLogoClick = async () => {
    setShowRanks(true);
    setShowRankMyUni(false);
    setShowUniMatching(false);
    setShowImpressum(false);
    showBar = true;
    updateBarPosition(rankRef)
    window.scrollTo(0, 0);
    await fetchUniversities(); // Also call it here when the button is clicked
  };

  const handleMatchAUniClick = async (activeButtonRef) => {
    setShowRanks(false);
    setShowRankMyUni(false);
    setShowUniMatching(true);
    setShowImpressum(false);
    updateBarPosition(activeButtonRef)
    showBar = true;
    handleFindMatchClick();
    window.scrollTo(0, 0);

  };

  const handleImpressumClick = async (activeButtonRef) => {
    setShowRanks(false);
    setShowRankMyUni(false);
    setShowUniMatching(false);
    setShowImpressum(true);
    showBar = false;
    updateBarPosition(activeButtonRef);
    window.scrollTo(0, 0);

    // handleFindMatchClick();
  };

  const mhandleShowRanksClick = async (activeButtonRef) => {
    setmModuleSelected(true);
    handleShowRanksClick(activeButtonRef);
    updatemTopBarHeight();
    setIsMenuVisible(false);

  };
  const mhandleRankMyUniClick = async (activeButtonRef) => {
    setmModuleSelected(true);
    handleRankMyUniClick(activeButtonRef);
    updatemTopBarHeight();
    setIsMenuVisible(false);

  };
  const mhandleMatchAUniClick = async (activeButtonRef) => {
    setmModuleSelected(true);
    handleMatchAUniClick(activeButtonRef);
    updatemTopBarHeight();
    setIsMenuVisible(false);
  };
  const mhandleImpressumClick = async (activeButtonRef) => {
    setmModuleSelected(true);
    handleImpressumClick(activeButtonRef)
    updatemTopBarHeight();
    setIsMenuVisible(false);
  };

  const mBackToMain = () => {
    setmModuleSelected(false);
    updatemTopBarHeight();
  };


  useEffect(() => {
    console.log('New mTopBarHeight:', mtopBarHeight);
  }, [mtopBarHeight]);






  //server at 212.132.64.180
  useEffect(() => {
    // Increment the count
    axios.post(`${address}/api/visit`);

    // Fetch the current count
    axios.get(`${address}/api/visitorCount`)
      .then(response => {
        console.log(response.data)
        setVisitorCount(response.data.count);
      })
      .catch(error => {
        console.error('Error fetching visitorCount:', error);
      });



  }, []);


  useEffect(() => {
    fetchUniversityData();
  }, []);


  const fetchUniversityData = async () => {
    try {
      const response = await axios.get(`${address}/api/unis`);
      console.log(response.data)
      setUniversityData(response.data);
    } catch (error) {
      console.error('Error fetching university data:', error);
    }
  };

  const fuse = new Fuse(universityData, { keys: ['name'], includeScore: true });


  useEffect(() => {
    axios.get(`${address}/api/categories`)
      .then(response => {
        setCategoriesMatching(response.data.questions);
        setCategoriesMatchingSpecial(response.data.questions2);
        // Initialize slider values
        const initialSliderValues = {};
        response.data.questions.forEach(question => {
          initialSliderValues[question.id] = 50; // Set initial value to 0
        });
        response.data.questions2.forEach(question => {
          initialSliderValues[question.id] = 50; // Set initial value to 0
        });
        setSliderValuesMatching(initialSliderValues);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  const handleMatchSliderChange = (id, value) => {
    setSliderValuesMatching(prev => ({ ...prev, [id]: value }));
  };


  const fetchUniversities = async () => {
    try {
      const response = await axios.get(`${address}/api/unis/averages`);
      console.log(response.data);
      const sortedUniversitiesOnlyVerified = response.data.onlyVerifiedResults.sort((a, b) => {
        return b.averageRating - a.averageRating; // Descending order
      });
      setUniversitiesRankedOnlyVerified(sortedUniversitiesOnlyVerified);

      const sortedUniversitiesCombined = response.data.combinedResults.sort((a, b) => {
        return b.averageRating - a.averageRating; // Descending order
      });
      setUniversitiesRankedCombined(sortedUniversitiesCombined);

      console.log("Only verified " + showOnlyVerified)
      if (showOnlyVerified) {
        setSelectedUniversitiesRanked(sortedUniversitiesOnlyVerified)
        setFullDetailUniversities(sortedUniversitiesOnlyVerified.map(uni => ({
          ...uni,
          showDetails: showAllDetailsForAll // Initially set to false
        })));
      } else {
        setSelectedUniversitiesRanked(sortedUniversitiesCombined);
        setFullDetailUniversities(sortedUniversitiesCombined.map(uni => ({
          ...uni,
          showDetails: showAllDetailsForAll // Initially set to false
        })));
      }



    } catch (error) {
      console.error('Error fetching universities:', error);
    }
  };

  useEffect(() => {
    fetchUniversities(); // Call it here for initial load
  }, []);




  const handleSearchInputChange = (event) => {
    const { value } = event.target;
    setSearchInputValue(value);
    setShowSearchDropdown(true);
    updateSearchDropdown(value);
  };

  const handleEmailInputChange = (event) => {
    const { value } = event.target;
    setEmailInputValue(value);
  };


  const handleEmailBlur = (event) => {
    const { value } = event.target;
    setEmailInputValue(value);
    handleEmailBlur2(value);
  };



  const handleEmailBlur2 = async (currentValue) => {
    try {
      const response = await axios.post(`${address}/api/checkEmail`, { email: currentValue, uniID: selectedUniversityData.id });
      console.log(response.data)

      if (response.data === 1) {
        setEmailWarning('Diese Adresse wurde schon verwendet');
      } else if (response.data === 2) {
        setEmailWarning('Diese Adresse scheint nicht zu deiner Uni zu gehören');
      } else {
        setEmailWarning(''); // Clear the warning if the email is valid        
      }
    } catch (error) {
      console.error('Error checking email:', error);
      setEmailWarning('Error occurred while checking email');
    }
  };



  const updateSearchDropdown = (value) => {
    if (value.trim() === '' || value.length === 0) {
      // If query is empty or spaces, return all items
      setFilteredResults([]);
    } else {
      const results = fuse.search(value);
      const matches = results.map(result => result.item);
      setFilteredResults(matches);
    }
  }

  const handleSearchDropdownItemClick = (word) => {
    setSearchInputValue(word.name);
    setShowSearchDropdown(false);
    search(word.name);
    setIsSubmitted(false);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowSearchDropdown(false);
      } else {

      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const handleDropdownKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      // Move down in the dropdown
      setHoveredIndex(prevIndex => {
        const newIndex = (prevIndex < filteredResults.length - 1) ? prevIndex + 1 : prevIndex;
        setSearchInputValue(filteredResults[newIndex].name);
        return newIndex;
      });

    } else if (event.key === "ArrowUp") {
      // Move up in the dropdown
      setHoveredIndex(prevIndex => {
        const newIndex = (prevIndex > 0) ? prevIndex - 1 : 0;
        setSearchInputValue(filteredResults[newIndex].name);
        return newIndex;
      });
    }

    if (event.key === "Enter") {

      if (showSearchDropdown === true) {

        if (inputRef.current) {
          inputRef.current.blur();
        }

        setHoveredIndex(prevIndex => {
          if (prevIndex !== -1) {
            setSearchInputValue(filteredResults[prevIndex].name);
            setShowSearchDropdown(false);
            search(filteredResults[prevIndex].name);
          }
          return prevIndex;
        });
      }
    }

    if (event.key === "Escape") {
      // Select the focused item
      setShowSearchDropdown(false);
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };


  // const handleUserInputChange = (index, value) => {
  //   const updatedResults = [...searchResults];

  //   updatedResults[index].userInput = value;
  //   setSearchResults(updatedResults);
  // };
  // const handleUserInputChangeSpecial = (index, value) => {
  //   const updatedResults = [...searchResultsSpecial];
  //   updatedResults[index].userInput = value;
  //   setSearchResultsSpecial(updatedResults);
  // };

  const handleUserInputChange = (id, value) => {
    const updatedResults = [...searchResults];
    const resultIndex = updatedResults.findIndex(result => result.id === id);
    if (resultIndex !== -1) {
      updatedResults[resultIndex].userInput = value;
      setSearchResults(updatedResults);
    } else {
      console.log('No result found with id:', id);
    }
  };
  
  const handleUserInputChangeSpecial = (id, value) => {
    const updatedResultsSpecial = [...searchResultsSpecial];
    const resultIndex = updatedResultsSpecial.findIndex(result => result.id === id);
    if (resultIndex !== -1) {
      updatedResultsSpecial[resultIndex].userInput = value;
      setSearchResultsSpecial(updatedResultsSpecial);
    } else {
      console.log('No special result found with id:', id);
    }
  };
  

  const handleSearchClick = () => {
    setShowSearchDropdown(false);
    setIsSubmitted(false);
    search(searchInputValue)
  };
  const handleCancelClick = () => {
    setSearchInputValue("");
    setShowSearchDropdown(false);
    setIsSubmitted(false);
    setSearchSuccess(false);
    setDidAlreadySearch(false);
  };


  const clickIntoInputField = () => {
    updateSearchDropdown(searchInputValue)
    setShowSearchDropdown(true)
    setHoveredIndex(0)
  };


  const search = (uni) => {

    axios.get(`${address}/api/search?query=${uni}`)
      .then(response => {
        if (response.data === "failed") {

          console.log("failed")

          setSearchResults([]);
          // setUniversityName(''); // Clear the university name
          // setUniversityImageUrl(''); // Clear the university image URL
          // setUniversityId(null); // Clear the university ID
          setSelectedUniversityData(null);
          setSearchSuccess(false);
          setDidAlreadySearch(true);

        } else {

          // Handle successful search
          const universityData = response.data;
          const questionResults = universityData.questions.map(question => ({
            id: question.id,
            text: question.name,
            userInput: '',
            marks: question.marks,
            position: question.position
          }));
          const questionResultsSpecial = universityData.questionsSpecial.map(question => ({
            id: question.id,
            text: question.name,
            userInput: '',
            marks: question.marks,
            position: question.position
          }));
          console.log("Nirmal", questionResults)
          console.log("Special", questionResultsSpecial)

          setSearchResults(initializeSearchResults(questionResults));
          setSearchResultsSpecial(initializeSearchResults(questionResultsSpecial));
          setSearchSuccess(true);
          setDidAlreadySearch(true);
          setSelectedUniversityData(universityData);

          // setUniversityName(universityData.name); // Set the university name
          // setUniversityImageUrl(universityData.url); // Set the university image URL
          // setUniversityId(universityData.id); // Set the university ID
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }


  const initializeSearchResults = (results) => {
    return results.map(result => ({
      ...result,
      userInput: result.userInput || 50 // Use existing userInput if present, otherwise default to 50
    }));
  };


  const handleSubmitClick = () => {
    const userInputData = {
      universityId: selectedUniversityData.id,
      responses: [
        ...searchResults.map(result => ({
          questionId: result.id,
          userInput: result.userInput,
          type: 'regular' // Identifying regular questions
        })),
        ...searchResultsSpecial.map(result => ({
          questionId: result.id,
          userInput: result.userInput,
          type: 'special' // Identifying special questions
        }))
      ]
    };


    // const scrollContainer = document.getElementById('container');
    // scrollContainer.scrollTo({ top: 0, behavior: 'smooth' });
    window.scrollTo(0, 0);

    setIsSubmitted(true);
    setHasSubmittedAVerifiedRanking(false);

    console.log(userInputData)
    axios.post(`${address}/api/submit`, userInputData)
      .then(response => {
        setTokenID(response.data.tokenID);
        setShowSubmissionError(!response.data.isOkay);
      })
      .catch(error => {
        console.error('Submission error', error);
      });
  };



  const handleSubmitClickVerified = () => {

    const userInputData = {
      universityId: selectedUniversityData.id,
      mail: emailInputValue,
      responses: [
        ...searchResults.map(result => ({
          questionId: result.id,
          userInput: result.userInput,
          type: 'regular' // Identifying regular questions
        })),
        ...searchResultsSpecial.map(result => ({
          questionId: result.id,
          userInput: result.userInput,
          type: 'special' // Identifying special questions
        }))
      ]
    };

    // console.log(userInputData)




    axios.post(`${address}/api/submitVerified`, userInputData)
      .then(response => {


        console.log('Submission successful. Token is: ', response.data);
        setTokenID(response.data.tokenID);
        if (response.data.code === 0) {
          window.scrollTo(0, 0);
          setIsSubmitted(true);
          setHasSubmittedAVerifiedRanking(true);
        }
        if (response.data.code === 1) {
          window.scrollTo(0, 0);
          setIsSubmitted(true);
          setShowSubmissionError(true);
        }
        if (response.data.code === 2) {
          setEmailWarning('Diese Adresse scheint nicht zu deiner Uni zu gehören');
        }
        console.log("my token set " + TokenID)

      })
      .catch(error => {
        console.error('Submission error', error);
      });



    // const userVerifyInputData = {
    //   mail: emailInputValue,
    //   tokenID: TokenID
    // };

    // console.log("my token get"+TokenID)

    // axios.post(`${address}/api/verifyData`,userVerifyInputData)
    //   .then(response => {
    //     console.log('Verify submission successful', response.data);
    //   })
    //   .catch(error => {
    //     console.error('Verify submission error', error);
    //   });
  };




  const verifyEmailAddress = () => {

    axios.post(`${address}/api/verifyData`, emailInputValue)
      .then(response => {
        console.log('Verify submission successful', response.data);
      })
      .catch(error => {
        console.error('Verify submission error', error);
      });
  };





  const openMailTo = () => {
    const emailAddress = 'info@campusranking.de'; // Replace with the desired email address
    const subject = 'Gib mir Support Junge'; // Replace with your desired subject
    const body = 'Hello, I would like to touch you.'; // Replace with your desired email body
    const mailtoUrl = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
  };



  const handleRankAnotherUni = () => {
    fetchUniversityData();
    fetchUniversities();
    setShowRanks(true);
    setShowRankMyUni(false);
    setIsSubmitted(false);
    setShowSubmissionError(false);
    setSearchSuccess(false);
    setIsSubmitted(false);
    setDidAlreadySearch(false);
    showBar = true;
    updateBarPosition(rankRef);
    window.scrollTo(0, 0);
  }


  const handleFindMatchClick = async () => {
    // Prepare the data for submission
    const dataToSubmit = Object.keys(sliderValuesMatching).map(categoryId => ({
      categoryId: categoryId,
      value: sliderValuesMatching[categoryId],

    }));

    // console.log("NOW THE SLIDER:")
    // console.log({dataToSubmit, showOnlyVerifiedForMatching})

    try {

      const response = await axios.post(`${address}/api/matching`, { dataToSubmit, showOnlyVerifiedForMatching });
      const sortedData = response.data.sort((a, b) => b.weightedSum - a.weightedSum);
      setUniversityMatches(response.data);
      console.log('Submission successful', response.data);
    } catch (error) {
      console.error('Error submitting data', error);
    }
  };





  const handleCheckboxChangeOnlyVerified = (event) => {
    setShowOnlyVerified(event.target.checked);
    checkboxChangeOnlyVerified(event.target.checked);

  };

  const toggleCheckboxChangeOnlyVerified = (event) => {
    const value = !showOnlyVerified;
    setShowOnlyVerified(value);
    checkboxChangeOnlyVerified(value);
  };

  const checkboxChangeOnlyVerified = (value) => {
    setShowOnlyVerified(value);

    if (value) {
      setSelectedUniversitiesRanked(universitiesRankedOnlyVerified)
      setFullDetailUniversities(universitiesRankedOnlyVerified.map(uni => ({
        ...uni,
        showDetails: showAllDetailsForAll // Initially set to false
      })));
    } else {
      setSelectedUniversitiesRanked(universitiesRankedCombined)
      setFullDetailUniversities(universitiesRankedCombined.map(uni => ({
        ...uni,
        showDetails: showAllDetailsForAll // Initially set to false
      })));
    }

  };


  useEffect(() => {
    // Call handleFindMatchClick whenever showOnlyVerifiedForMatching changes
    handleFindMatchClick();
  }, [showOnlyVerifiedForMatching]);

  const handleCheckboxChangeOnlyVerifiedForMatching = (event) => {
    setShowOnlyVerifiedForMatching(event.target.checked);
  };

  const toggleCheckboxChangeOnlyVerifiedForMatching = () => {
    const value = !showOnlyVerifiedForMatching;
    setShowOnlyVerifiedForMatching(value);
  };

  const handleCheckboxChangeShowAllDetailsForAll = (event) => {
    setShowAllDetailsForAll(event.target.checked);
    CheckboxChangeShowAllDetailsForAll(event.target.checked)
  };


  const toggleCheckboxChangeShowAllDetailsForAll = () => {
    const value = !showAllDetailsForAll;
    setShowAllDetailsForAll(value);
    CheckboxChangeShowAllDetailsForAll(value)
  };
  const toggleFilterOptions = () => {
    const value = !isFilterOptionsVisible;
    setIsFilterOptionsVisible(value)
  };
  const toggleDisplayOptions = () => {
    const value = !isDisplayOptionsVisible;
    setIsDisplayOptionsVisible(value);
  };
  const toggleMatchingOptions = () => {
    const value = !isMatchingOptionsVisible;
    setIsMatchingOptionsVisible(value);
  };
  const CheckboxChangeShowAllDetailsForAll = (value) => {
    setFullDetailUniversities(currentUniversities =>
      currentUniversities.map(uni => ({
        ...uni,
        showDetails: value // Set showDetails based on the checkbox state
      }))
    );
  }

  const clickOutOfInputField = () => {
    setShowSearchDropdown(false); // Hide the dropdown when the input field loses focus
  };


  const theme = createTheme({
    components: {
      MuiCheckbox: {
        styleOverrides: {
          "root": {
            "&.Mui-focusVisible": {
              "backgroundColor": "var(--c-slidershaddow)",
              "borderRadius": "50%"
            }
          }
        }
      }
    }
  });

  const schemaOrgJSONLD = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": "campusranking.de",
    "url": "https://www.campusranking.de"
    // Add more details as needed
  };


  return (
    <div className="container">


      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
      </Helmet>


      {isMobile && (

        <nav className={`slide-in-menu ${isMenuVisible ? 'visible' : ''}`}>
          <div className="tb1_2" onClick={toggleMenu}>
            <div className='tb2'>
              <img
                className="menu"
                src="/images/cancel.png"
                alt="Cancel"
              />
            </div>
          </div>

          <div className="header-button-container">
            <button onClick={() => mhandleShowRanksClick(rankRef)} className={`header-button ${showRanks ? 'header-button-active' : ''}`}>
              Top Ranked
            </button>
            <button onClick={() => mhandleRankMyUniClick(rateRef)} className={`header-button ${showRankMyUni ? 'header-button-active' : ''}`}>
              Rate your Campus
            </button>
            <button onClick={() => mhandleMatchAUniClick(matchRef)} className={`header-button ${showUniMatching ? 'header-button-active' : ''}`}>
              Match!
            </button>
          </div>

          <div className="about-container" >
            <button ref={impressumRef} onClick={() => mhandleImpressumClick(impressumRef)} className={`header-button ${showImpressum ? 'header-button-active' : ''}`}>
              About
            </button>
          </div>
        </nav>

      )}



      {isMobile ? (

        <header className="tb1" ref={mtopBarRef} onClick={toggleMenu}>

          <div className='tb2'>
            <img
              className="menu"
              src="/images/menu.png"
              alt="Menu"

            />
            <img
              src="/images/logo2.png"
              alt="Campusranking Icon"
              className="icon"

            />
            <div
              className="bufferItem"
            >
            </div>
          </div>


        </header>



      ) : (



        <header className="topBar" ref={topBarRef}>
          <div className="section section-1">

            <img
              src="/images/logo2.png"
              alt="Campusranking Icon"
              onClick={handleLogoClick}
              style={{
                width: 'auto',
                height: '45px',
                marginTop: '10px',
                marginLeft: '100px',
                marginRight: '50px',
              }}
            />
          </div>



          <nav className="header-button-container">
            <button ref={rankRef} onClick={() => handleShowRanksClick(rankRef)} className={`header-button ${showRanks ? 'header-button-active' : ''}`}>
              Top Ranked
            </button>
            <button ref={rateRef} onClick={() => handleRankMyUniClick(rateRef)} className={`header-button ${showRankMyUni ? 'header-button-active' : ''}`}>
              Rate your Campus
            </button>
            <button ref={matchRef} onClick={() => handleMatchAUniClick(matchRef)} className={`header-button ${showUniMatching ? 'header-button-active' : ''}`}>
              Find a Match
            </button>
            <div className="header-moving-bar" style={barStyle}></div>
          </nav>

          <footer className="section section-3">
            <div className="centered-container" style={{ marginLeft: '50px', marginRight: '50px' }}>
              <button ref={impressumRef} onClick={() => handleImpressumClick(impressumRef)} className={`header-button ${showImpressum ? 'header-button-active' : ''}`}>
                About
              </button>
              {/* <p className="whiteText" style={{ marginRight: '20px'}}>Visitors: {visitorCount}</p>  */}

            </div>
          </footer>




        </header>

      )}


      {isMobile ? (

        <div style={{ paddingTop: mtopBarHeight - 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          <nav className="mModuleSelectButtons">
            <button ref={rankRef} onClick={() => mhandleShowRanksClick(rankRef)} className={`mModuleSelectButton ${showRanks ? 'mModuleSelectButton-active' : ''}`}>
              Top Ranked
            </button>
            <button ref={rateRef} onClick={() => mhandleRankMyUniClick(rateRef)} className={`mModuleSelectButton ${showRankMyUni ? 'mModuleSelectButton-active' : ''}`}>
              Rate your Campus
            </button>
            <button ref={matchRef} onClick={() => mhandleMatchAUniClick(matchRef)} className={`mModuleSelectButton ${showUniMatching ? 'mModuleSelectButton-active' : ''}`}>
              Find a Match
            </button>

          </nav>


          <div style={{ width: '100%' }}>
            {showRanks && (

              <article className='mainBackground' style={{ width: '100%' }}>
                <Helmet>
                  <title>Die besten Unis und Hochschulen</title>
                  <meta name="description" content="Die besten deutschen Hochschulen und Unis basierend auf dem Ranking der Studenten. Die besten Campi. Der beste Campus." />
                </Helmet>
                <div className='introText' style={{ minHeight: topBarHeight * 1 }}>
                  <p>
                    Im großen <strong>Campusranking 2024</strong> können über <strong>100 deutsche Unis und Hochschulen</strong> geranked werden.
                  </p>
                </div>

                <div className='banner' style={{ width: '100%', height: mtopBarHeight }}>
                  <p>
                    Wir ranken Unileben, fick die Bildung
                  </p>
                </div>


                {
                  !isDisplayOptionsVisible && !isFilterOptionsVisible && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'right', justifyContent: 'left', margin: '20px', marginBottom: '20px', marginTop: '30px' }}>
                      <div className='filterButton' onClick={toggleDisplayOptions}>Anzeige</div>
                      <div className='filterButton' onClick={toggleFilterOptions}>Filter</div>
                    </div>
                  )}

                {isDisplayOptionsVisible && (
                  <div className='rankingOptions' >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                      <h3>Anzeige:</h3>
                      <button onClick={toggleDisplayOptions}>OK</button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '40px' }} onClick={toggleCheckboxChangeShowAllDetailsForAll}>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          checked={showAllDetailsForAll}
                          onChange={handleCheckboxChangeShowAllDetailsForAll}
                          sx={{
                            color: 'white', // Color when not checked
                            '&.Mui-checked': {
                              color: 'white', // Color when checked
                              '&:hover': {
                                backgroundColor: 'transparent', // Remove default hover color
                              }
                            },
                            '&:hover': {
                              backgroundColor: 'transparent', // Remove default hover color
                            }
                          }}
                        />
                      </ThemeProvider>

                      <label htmlFor="checkbox1">Alle Details zeigen</label>
                    </div>

                  </div>
                )}

                {isFilterOptionsVisible && (
                  <div className='rankingOptions'  >

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px', paddingLeft: '10px' }}>
                      <h3>Filter:</h3>
                      <button onClick={toggleFilterOptions}>OK</button>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '40px' }} onClick={toggleCheckboxChangeOnlyVerified}>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          checked={showOnlyVerified}
                          onChange={handleCheckboxChangeOnlyVerified}
                          sx={{
                            color: 'white', // Color when not checked
                            '&.Mui-checked': {
                              color: 'white', // Color when checked
                              '&:hover': {
                                backgroundColor: 'transparent', // Remove default hover color
                              }
                            },
                            '&:hover': {
                              backgroundColor: 'transparent', // Remove default hover color
                            }
                          }}
                        />
                      </ThemeProvider>
                      <label htmlFor="checkbox2">Nur verifizierte Bewertungen</label>
                    </div>

                  </div>
                )}


                <h3 style={{ margin: '10px', marginTop: '10px', padding: '10px' }}>Die geilsten Unis und Hochschulen:</h3>


                <Ranking
                  showOnlyVerified={showOnlyVerified}
                  fullDetailUniversities={fullDetailUniversities}
                  toggleUniversityDetails={toggleUniversityDetails}
                  isMobile={isMobile}
                />

                <p style={{ textAlign: 'center', margin: '30px 50px' }}>Deine Uni/Hochschule taucht hier nicht auf? Dann sag Leuten die dort studieren, die sollen sie mal ranken!</p>

              </article>

            )}

            {showUniMatching && (

              <article className='mainBackground' style={{ width: '100%' }}>
                <Helmet>
                  <title>Finde deinen passenden Campus</title>
                  <meta name="description" content="Finde den perfekten Hochschul-Campus oder Universitäts-Campus. Was sind deine Wünsche?" />
                </Helmet>

                <div className='introText' style={{ minHeight: topBarHeight * 1 }}>
                  <p>
                    Sag uns was du von deinem zukünftigen Campus willst und wir berechnen den für dich <strong>besten Match!</strong>
                  </p>
                </div>

                <div className='banner' style={{ width: '100%', height: mtopBarHeight }}>
                  <p>
                    Sag uns was du willst
                  </p>
                </div>

                <div style={{ width: '100%' }}>


                  {isMatchingOptionsVisible ? (
                    <div className='matchingOptions' >

                      <div className='matchingOptionsTitle'>
                        <h2>
                          Deine Wünsche
                        </h2>
                        <button onClick={toggleMatchingOptions}>Fertig</button>
                      </div>

                      <MatchQuestions1
                        categoriesMatchingSpecial={categoriesMatchingSpecial}
                        handleFindMatchClick={handleFindMatchClick}
                        sliderValuesMatching={sliderValuesMatching}
                        handleMatchSliderChange={handleMatchSliderChange}
                        isMobile={isMobile}
                      />
                      <MatchQuestions2
                        categoriesMatching={categoriesMatching}
                        sliderValuesMatching={sliderValuesMatching}
                        handleMatchSliderChange={handleMatchSliderChange}
                        handleFindMatchClick={handleFindMatchClick}
                        isMobile={isMobile}
                      />


                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px' }} onClick={toggleCheckboxChangeOnlyVerifiedForMatching}>
                        <ThemeProvider theme={theme}>
                          <Checkbox
                            checked={showOnlyVerifiedForMatching}
                            onChange={handleCheckboxChangeOnlyVerifiedForMatching}
                            sx={{
                              color: 'white', // Color when not checked
                              '&.Mui-checked': {
                                color: 'white', // Color when checked
                                '&:hover': {
                                  backgroundColor: 'transparent', // Remove default hover color
                                }
                              },
                              '&:hover': {
                                backgroundColor: 'transparent', // Remove default hover color
                              }
                            }}
                          />
                        </ThemeProvider>
                        <label htmlFor="checkbox2">Nur verifizierte Bewertungen nutzen</label>


                      </div>
                      <button style={{ width: '100%' }} onClick={toggleMatchingOptions}>Fertig</button>
                    </div>

                  ) : (

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div style={{ width: '100%', margin: '20px 0px' }}>

                        <button className='mMatchingOptions' onClick={toggleMatchingOptions} style={{ width: '90%' }}>

                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <img
                              className="matchingSettingsIcon"
                              src="/images/settings.png"
                              alt="Settings for finding the perfect Campus"
                            />
                            <div className="matchingSettingsText">

                              Gib hier an was du willst

                            </div>

                          </div>

                        </button>

                      </div>

                      <div style={{ width: '90%', marginTop: '30px' }}
                      >
                        <MatchResults
                          universityMatches={universityMatches}
                          showOnlyVerifiedForMatching={showOnlyVerifiedForMatching}
                          isMobile={isMobile}
                        />
                      </div>
                    </div>



                  )}

                </div>


              </article>
            )}

            {showRankMyUni && (

              <article className='mainBackground' style={{ paddingTop: '0vh', width: '100%' }} >
                <Helmet>
                  <title>Bewerte deine Uni</title>
                  <meta name="description" content="Bewerte den Campus deiner Hochschule oder Universität nach den Kategorien die wirklich zählen." />
                </Helmet>

                <div className='introText' style={{ minHeight: topBarHeight * 1 }}>
                  <p>
                    Such deine Uni oder Hochschule und <strong>sag was du denkst!</strong>
                    <br />
                    <strong>Verifizierte Bewertungen zählen obviously mehr!</strong>

                  </p>
                </div>

                <div className='banner' style={{ width: '100%', height: mtopBarHeight }}>
                  <p>
                    8 Fragen zack fertig
                  </p>
                </div>


                <div style={{ width: '100%' }}>

                  {searchSuccess ? (

                    <div style={{ width: '100%' }}>
                      {isSubmitted ? (
                        <div>
                        </div>
                      ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '20px' }}>
                          <div style={{ display: 'flex', padding: 0, position: 'relative', alignItems: 'center' }}
                            tabIndex="0" onKeyDown={handleDropdownKeyDown}>
                            <h3 className='selectedUni' style={{ margin: '0 10px 0 0', flexGrow: 0, maxWidth: '50vw', textAlign: 'center' }}>{selectedUniversityData.name}</h3>
                            <button onClick={handleCancelClick} className="buttonSearch" style={{ flex: '0 1 auto', margin: '10px', padding: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <img src="images/cancel.png" alt="Cancel" style={{ maxWidth: '20px', maxHeight: '20px' }} />
                            </button>
                          </div>
                        </div>


                      )}
                    </div>
                  ) : (

                    <Search
                      handleDropdownKeyDown={handleDropdownKeyDown}
                      inputRef={inputRef}
                      searchInputValue={searchInputValue}
                      handleSearchInputChange={handleSearchInputChange}
                      clickIntoInputField={clickIntoInputField}
                      showSearchDropdown={showSearchDropdown}
                      filteredResults={filteredResults}
                      dropdownRef={dropdownRef}
                      hoveredIndex={hoveredIndex}
                      setHoveredIndex={setHoveredIndex}
                      handleSearchDropdownItemClick={handleSearchDropdownItemClick}
                      handleSearchClick={handleSearchClick}
                      isMobile={isMobile}
                    />

                  )}

                </div>

                {searchSuccess ? (
                  <div style={{ width: '100%' }}>
                    {isSubmitted ?
                      (

                        showSubmissionError ? (
                          <div style={{ marginTop: '40px', padding: '10px', textAlign: 'center', borderRadius: '7px' }}>
                            <h3 style={{ margin: '0 30px' }}>Scheint so als hättest du deinen Campus schon bewertet &#128528;</h3>

                            <button style={{ marginTop: '60px' }} onClick={handleRankAnotherUni} className="buttonAfterRating">
                              Zurück zur Bestenliste
                            </button>
                          </div>
                        ) : (


                          hasSubmittedAVerifiedRanking ? (
                            <div style={{ margin: '20px', padding: '20px', textAlign: 'center', borderRadius: '7px' }}>
                              <h3 style={{ marginBottom: '40px' }}>Thanks! You're cute &#128527; <br></br><br></br>Du hast ne Mail bekommen!<br></br> Klick da auf den Link und verifizier dich!</h3>

                              <button onClick={handleRankAnotherUni} className="buttonAfterRating">
                                Habs brav erledigt &#128519;
                              </button>
                            </div>
                          ) : (
                            <div style={{ margin: '30px 10px', padding: '10px', textAlign: 'center', borderRadius: '7px' }}>
                              <h3 style={{ marginBottom: '30px' }}>Thanks! You're cute &#128527;</h3>


                              <button onClick={handleRankAnotherUni} className="buttonAfterRating">
                                Zurück zur Bestenliste
                              </button>
                            </div>
                          )

                        )

                      ) : (
                        <div style={{ padding: '0px', borderRadius: '20px', display: 'flex', flexDirection: 'column', width: 'auto', margin: '0' }}>

                          <div style={{ paddingTop: '0vh' }} >


                            <Questions1
                              searchResultsSpecial={searchResultsSpecial}
                              handleUserInputChangeSpecial={handleUserInputChangeSpecial}
                              isMobile={isMobile}
                            />

                            <Questions2
                              searchResults={searchResults}
                              handleUserInputChange={handleUserInputChange}
                              isMobile={isMobile}
                            />

                            <EmailField
                              emailInputValue={emailInputValue}
                              handleEmailInputChange={handleEmailInputChange}
                              handleEmailBlur={handleEmailBlur}
                              handleEmailBlur2={handleEmailBlur2}
                              selectedUniversityData={selectedUniversityData}
                              emailWarning={emailWarning}
                              isMobile={isMobile}
                            />



                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '20px' }}>
                              <button onClick={handleSubmitClickVerified} className="buttonVerifiedSubmit">Abschicken</button>
                              <button onClick={handleSubmitClick} className="buttonSubmit">ohne Verifizierung abschicken</button>
                              <p style={{ alignItems: 'top', textAlign: 'center', width: '50%', fontSize: 'x-small' }}>*nicht verifizierte Votes zählen deutlich weniger</p>
                            </div>



                          </div>

                        </div>
                      )}
                  </div>
                ) : (

                  <div>
                    {didAlreadySearch ? (

                      <div className='centered' style={{ margin: '10px', padding: '10px' }}>
                        <h3>
                          Wir konnten deine Uni nicht finden :(
                        </h3>
                      </div>

                    ) : (

                      <div className='centered' style={{ margin: '10px', padding: '10px' }}>
                        <h3>
                        </h3>
                      </div>
                    )}
                  </div>

                )}


              </article>


            )}

            {showImpressum && (
              <article className='mainBackground' style={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <Helmet>
                  <title>About</title>
                  <meta name="description" content="Alle rechtlichen Informationen." />
                </Helmet>
                <div className='banner' style={{ width: '100%', height: mtopBarHeight }}>
                  <h3>
                    Rechtliches Gedöns und so
                  </h3>
                </div>

                <About
                  toggleFeaturing={toggleFeaturing}
                  isFeaturingExpanded={isFeaturingExpanded}
                  toggleImpressum={toggleImpressum}
                  isImpressumExpanded={isImpressumExpanded}
                  toggleDatenschutz={toggleDatenschutz}
                  isDatenschutzExpanded={isDatenschutzExpanded}
                />

              </article>
            )}


          </div>


        </div>



      ) : (

        <div style={{ paddingTop: topBarHeight - 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

          {showRanks && (
            <article className='mainBackground' style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
              <Helmet>
                <title>Die geilsten Unis und Hochschulen</title>
                <meta name="description" content="Die besten deutschen Hochschulen und Unis basierend auf dem Ranking der Studenten. Die besten Campi. Der beste Campus." />
              </Helmet>


              <div className='introText' style={{ display: 'flex', flexDirection: 'column', minHeight: topBarHeight * 1.5 }}>

                <p>
                  Im großen <strong>Campusranking 2024</strong> können über <strong>100 deutsche Unis und Hochschulen</strong> geranked werden.
                  <br />
                  <br />
                  Anders als bei anderen Uni-Rankings legen wir den <strong>Fokus</strong> komplett auf den <strong>Lifestyle am Campus</strong>.
                  <br />
                  Wen juckt schon die Doktoranden-Quote an deiner zukünftigen Uni.
                  <br />
                  <br />
                  Die Unis und Hochschulen werden alle direkt <strong>von den Studierenden</strong> geranked.
                </p>
              </div>


              <div className='banner' style={{ width: '100%', height: topBarHeight }}>
                <h3>
                  Wir ranken Unileben, fick die Bildung
                </h3>
              </div>



              <div style={{ width: '80%', display: 'grid', gridTemplateColumns: '3fr 8fr', gap: '120px', margin: '10px auto', marginTop: '70px', padding: '10px' }}>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', margin: '20px' }}>
                  <h3>Was zeigen?</h3>

                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={toggleCheckboxChangeShowAllDetailsForAll}>


                    <ThemeProvider theme={theme}>
                      <Checkbox
                        checked={showAllDetailsForAll}
                        onChange={handleCheckboxChangeShowAllDetailsForAll}
                        sx={{
                          color: 'black', // Color when not checked
                          '&.Mui-checked': {
                            color: 'black', // Color when checked
                            '&:hover': {
                              backgroundColor: 'transparent', // Remove default hover color
                            }
                          },
                          '&:hover': {
                            backgroundColor: 'transparent', // Remove default hover color
                          }
                        }}
                      />
                    </ThemeProvider>

                    <label htmlFor="checkbox1">Alle Details zeigen</label>
                  </div>

                  <h3 style={{ marginTop: '50px' }} >Filtern?</h3>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={toggleCheckboxChangeOnlyVerified}>
                    <ThemeProvider theme={theme}>
                      <Checkbox
                        checked={showOnlyVerified}
                        onChange={handleCheckboxChangeOnlyVerified}
                        sx={{
                          color: 'black', // Color when not checked
                          '&.Mui-checked': {
                            color: 'black', // Color when checked
                            '&:hover': {
                              backgroundColor: 'transparent', // Remove default hover color
                            }
                          },
                          '&:hover': {
                            backgroundColor: 'transparent', // Remove default hover color
                          }
                        }}
                      />
                    </ThemeProvider>
                    <label htmlFor="checkbox2">Nur verifizierte Bewertungen</label>
                  </div>


                </div>

                <div>
                  <h3 style={{ margin: '10px', padding: '10px' }}>Die besten Unis und Hochschulen</h3>

                  <Ranking
                    showOnlyVerified={showOnlyVerified}
                    fullDetailUniversities={fullDetailUniversities}
                    toggleUniversityDetails={toggleUniversityDetails}
                    isMobile={isMobile}
                  />

                  <p style={{ textAlign: 'center', margin: '60px 50px' }}>Deine Uni/Hochschule taucht hier nicht auf?<br></br> Dann sag Leuten die dort studieren, die sollen sie mal ranken!</p>

                </div>


              </div>
            </article>
          )}

          {showRankMyUni && (

            <article className='mainBackground'
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
              }}>

              <Helmet>
                <title>Bewerte deine Uni</title>
                <meta name="description" content="Bewerte den Campus deiner Hochschule oder Universität nach den Kategorien die wirklich zählen." />
              </Helmet>

              <div className='introText' style={{ minHeight: topBarHeight * 1 }}>
                <p>
                  Such deine Uni oder Hochschule und lasse dein Urteil <strong>auf sie herabregnen</strong>.
                  <br />
                  Deine Bewertungen sind ein Kuss an alle zukünftigen Studierenden-Generationen.
                  <br />
                  <br />
                  Vergiss nicht deine Uni-E-Mail Adresse anzugeben! <strong>Verifizierte Bewertungen zählen mehr!</strong>

                </p>
              </div>


              <div className='banner' style={{ width: '100%', height: topBarHeight }}>
                <h3>
                  8 Fragen zack fertig
                </h3>
              </div>

              <div style={{ width: '70%', margin: '20px', padding: '10px' }}>
                <div className='centered'>
                  <div>

                    {searchSuccess ? (

                      <div>
                        {isSubmitted ? (
                          <div>
                          </div>
                        ) : (
                          <div style={{ width: '30vw', padding: '0px', alignItems: 'center', display: 'flex', justifyContent: 'center', position: 'relative' }}>

                            <div style={{ flex: 'none', margin: 0, padding: 0, position: 'relative' }}
                              tabIndex="0" onKeyDown={handleDropdownKeyDown}
                            >
                              <h3 className='selectedUni'>{selectedUniversityData.name}</h3>
                            </div>


                            <div style={{ flex: 'none' }}>
                              <button onClick={handleCancelClick} className="buttonSearch">
                                <img src="images/cancel.png" alt="Cancel" style={{ maxWidth: '30px', maxHeight: '30px' }} />
                              </button>
                            </div>

                          </div>
                        )}
                      </div>
                    ) : (

                      <Search
                        handleDropdownKeyDown={handleDropdownKeyDown}
                        inputRef={inputRef}
                        searchInputValue={searchInputValue}
                        handleSearchInputChange={handleSearchInputChange}
                        clickIntoInputField={clickIntoInputField}
                        showSearchDropdown={showSearchDropdown}
                        filteredResults={filteredResults}
                        dropdownRef={dropdownRef}
                        hoveredIndex={hoveredIndex}
                        setHoveredIndex={setHoveredIndex}
                        handleSearchDropdownItemClick={handleSearchDropdownItemClick}
                        handleSearchClick={handleSearchClick}
                      />

                    )}

                  </div>

                  {searchSuccess ? (
                    <div style={{ width: '85vw', maxWidth:'1400px' }}>
                      {isSubmitted ?
                        (

                          showSubmissionError ? (
                            <div style={{ marginTop: '40px', padding: '10px', textAlign: 'center', borderRadius: '7px' }}>
                              <h3>Scheint so als hättest du deinen Campus schon bewertet &#128528;</h3>

                              <button style={{ marginTop: '60px' }} onClick={handleRankAnotherUni} className="buttonAfterRating">
                                Zurück zur Bestenliste
                              </button>
                            </div>
                          ) : (


                            hasSubmittedAVerifiedRanking ? (
                              <div style={{ margin: '20px', padding: '20px', textAlign: 'center', borderRadius: '7px' }}>
                                <h3 style={{ marginBottom: '40px' }}>
                                Thanks! You're cute &#128527; <br></br><br></br>Du hast ne Mail bekommen! <br></br> Klick da auf den Link und verifizier dich!</h3>

                                <button onClick={handleRankAnotherUni} className="buttonAfterRating">
                                  Habs brav erledigt &#128519;
                                </button>
                              </div>
                            ) : (
                              <div style={{ margin: '10px', padding: '10px', textAlign: 'center', borderRadius: '7px' }}>
                                <h3>Thanks, you're cute &#128527;</h3>


                                <button onClick={handleRankAnotherUni} className="buttonAfterRating">
                                  Zurück zur Bestenliste
                                </button>
                              </div>
                            )

                          )

                        ) : (
                          <div style={{ padding: '30px', borderRadius: '20px', display: 'flex', flexDirection: 'column', width: 'auto' }}>

                            <div style={{ paddingTop: '0vh' }} >


                              <Questions1
                                searchResultsSpecial={searchResultsSpecial}
                                handleUserInputChangeSpecial={handleUserInputChangeSpecial}
                              />

                              <Questions2
                                searchResults={searchResults}
                                handleUserInputChange={handleUserInputChange}
                              />

                              <EmailField
                                emailInputValue={emailInputValue}
                                handleEmailInputChange={handleEmailInputChange}
                                handleEmailBlur={handleEmailBlur}
                                handleEmailBlur2={handleEmailBlur2}
                                selectedUniversityData={selectedUniversityData}
                                emailWarning={emailWarning}
                              />



                                <div className='centered' style={{ display: 'grid', gap: '50px', gridTemplateColumns: '15vw 25vw', gridTemplateRows: '0vw', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '100px' }}>
                                  <button onClick={handleSubmitClick} className="buttonSubmit">ohne Verifizierung abschicken</button>
                                  <button onClick={handleSubmitClickVerified} className="buttonVerifiedSubmit">Abschicken</button>
                                  <p style={{ alignItems: 'top', width: '100%', fontSize: 'small', margin: '20px 45px 0px 20px' }}>*nicht verifizierte Votes werden deutlich schwächer gewichtet</p>
                                </div>



                            </div>

                          </div>
                        )}
                    </div>
                  ) : (

                    <div>
                      {didAlreadySearch ? (

                        <div className='centered' style={{ margin: '10px', padding: '10px' }}>
                          <h3>
                            Wir konnten deine Uni nicht finden &#128553;
                          </h3>
                        </div>

                      ) : (

                        <div className='centered' style={{ margin: '10px', padding: '10px' }}>
                          <h3>
                          </h3>
                        </div>
                      )}
                    </div>

                  )}


                </div>
              </div>
            </article>

          )}

          {showUniMatching && (


            <article className='mainBackground' style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}>
              <Helmet>
                <title>Finde den passenden Campus</title>
                <meta name="description" content="Finde den perfekten Hochschul- Universitäts-Campus. Was sind deine Präferenzen?" />
              </Helmet>

              <div className='introText' style={{ minHeight: topBarHeight * 1 }}>
                <p>
                  Sag uns was du von deinem zukünftigen Campus willst und wir berechnen den für dich <strong>besten Match</strong>.
                  <br></br>
                  <br></br>
                  Basierend auf den Bewertungen <strong>von anderen Studierenden</strong>.
                </p>
              </div>



              <div className='banner' style={{ width: '100%', height: topBarHeight }}>
                <h3>
                  Sag uns was du willst
                </h3>
              </div>



              <div style={{ width: '90%', maxWidth:'1500px', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '2vw', margin: '10px auto', marginTop: '70px', padding: '10px' }}>


                <div>
                  <MatchQuestions1
                    categoriesMatchingSpecial={categoriesMatchingSpecial}
                    handleFindMatchClick={handleFindMatchClick}
                    sliderValuesMatching={sliderValuesMatching}
                    handleMatchSliderChange={handleMatchSliderChange}
                  />
                  <MatchQuestions2
                    categoriesMatching={categoriesMatching}
                    sliderValuesMatching={sliderValuesMatching}
                    handleMatchSliderChange={handleMatchSliderChange}
                    handleFindMatchClick={handleFindMatchClick}
                  />


                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={toggleCheckboxChangeOnlyVerifiedForMatching}>
                    <ThemeProvider theme={theme}>
                      <Checkbox
                        checked={showOnlyVerifiedForMatching}
                        onChange={handleCheckboxChangeOnlyVerifiedForMatching}
                        sx={{
                          color: 'black', // Color when not checked
                          '&.Mui-checked': {
                            color: 'black', // Color when checked
                            '&:hover': {
                              backgroundColor: 'transparent', // Remove default hover color
                            }
                          },
                          '&:hover': {
                            backgroundColor: 'transparent', // Remove default hover color
                          }
                        }}
                      />
                    </ThemeProvider>
                    <label htmlFor="checkbox2">Nur verifizierte Bewertungen nutzen</label>
                  </div>
                </div>


                <MatchResults
                  universityMatches={universityMatches}
                  showOnlyVerifiedForMatching={showOnlyVerifiedForMatching}
                  isMobile={isMobile}
                />


              </div>
            </article>


          )}


          {showImpressum && (

            <article className='mainBackground' style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}>

              <Helmet>
                <title>About</title>
                <meta name="description" content="Alle rechtlichen Informationen." />
              </Helmet>

              <div className='banner' style={{ width: '100%', height: topBarHeight }}>
                <h3>
                  Rechtliches Gedöns und so
                </h3>
              </div>

              <About
                toggleFeaturing={toggleFeaturing}
                isFeaturingExpanded={isFeaturingExpanded}
                toggleImpressum={toggleImpressum}
                isImpressumExpanded={isImpressumExpanded}
                toggleDatenschutz={toggleDatenschutz}
                isDatenschutzExpanded={isDatenschutzExpanded}
              />




            </article>

          )}

        </div>


      )}

      {<div className='mainBackground' style={{ display: 'flex', flexDirection: 'column', height: '400px', alignItems: 'center' }}>
        {/* <button className={`buttonSecondary`} onClick={openMailTo}>
              Contact
                </button> */}

      </div>}

    </div>
  );



};

export default App;

