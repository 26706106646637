




import React from 'react';



const MatchResults = ({ universityMatches, showOnlyVerifiedForMatching, isMobile

}) => {
  return (


    <div style={{ width: '100%'}}>
      <h3 >Deine besten Matches:</h3>
      {universityMatches.slice(0, 100).map((uni, index) => (



        <div key={index} className='uniItemMatching' >

          <div className='centeredVertically' style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }}>


            <div style={{ flex: 3, minWidth: '60%', display: 'flex', justifyContent: 'center', width: '100%', flexDirection: 'column' }}>

              <div className='centeredVertically' style={{ flex: 1, minWidth: '60%', display: 'flex', justifyContent: 'left', width: '100%', flexDirection: 'row' }}>

              <h4 style={{ marginRight: '30px', whiteSpace: 'nowrap' }}># {index + 1}</h4>
                <p style={{ backgroundColor: '#222', color: '#fff', padding: '15px', borderRadius: '10px', display: 'inline-block', marginRight: '20px' }}>{`${(uni.weightedSum * 100).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%`}</p>
                <h3>{uni.uniName}</h3>

              </div>

              {isMobile && (
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>

                  {showOnlyVerifiedForMatching ? (
                    <div style={{ flex: 'none' }}>
                      <p className='reviewCount2'>{"Basierend auf "}{Math.round(uni.minReviewsPerCategoryVerified)} {Math.round(uni.minReviewsPerCategoryVerified) < 2 ? "verifizierten Bewertung" : "verifizierten Bewertungen"}</p>
                    </div>

                  ) : (
                    <p className='reviewCount2'>{"Basierend auf "}{Math.round(uni.minReviewsPerCategoryVerified) + Math.round(uni.minReviewsPerCategoryNonVerified)} {(Math.round(uni.minReviewsPerCategoryVerified) + Math.round(uni.minReviewsPerCategoryNonVerified)) < 2 ? "Bewertung" : "Bewertungen"}</p>
                  )
                  }
                </div>
              )}

            </div>

            {!isMobile && (
              <div style={{ flex: 1, display: 'flex', justifyContent: 'right' }}>

                {showOnlyVerifiedForMatching ? (
                  <div style={{ flex: 'none' }}>
                    <p className='reviewCount'>{Math.round(uni.minReviewsPerCategoryVerified)} {Math.round(uni.minReviewsPerCategoryVerified) < 2 ? "verifizierte Bewertung" : "verifizierte Bewertungen"}</p>
                  </div>

                ) : (
                  <p className='reviewCount'>{Math.round(uni.minReviewsPerCategoryVerified) + Math.round(uni.minReviewsPerCategoryNonVerified)} {(Math.round(uni.minReviewsPerCategoryVerified) + Math.round(uni.minReviewsPerCategoryNonVerified)) < 2 ? "Bewertung" : "Bewertungen"}</p>
                )
                }
              </div>
            )}



          </div>
        </div>
      ))}
    </div>



  )
};

export default MatchResults;