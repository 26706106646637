import React from 'react';



const Search = ({ handleDropdownKeyDown, inputRef, searchInputValue, handleSearchInputChange, clickIntoInputField, showSearchDropdown, filteredResults, dropdownRef, hoveredIndex, setHoveredIndex, handleSearchDropdownItemClick, handleSearchClick, isMobile }) => {
  return (

    <div className='searchContainer'>

      <div style={{ flex: 4, margin: 0, padding: 0, position: 'relative' }}
        tabIndex="0" onKeyDown={handleDropdownKeyDown}
      >

        <div className='searchBarContainer'>
          <input ref={inputRef} className='searchBar' type="text" value={searchInputValue}
            onChange={handleSearchInputChange}
            onFocus={() => clickIntoInputField()}
            placeholder="Wo studierst du?"
          />

        </div>

        {showSearchDropdown && filteredResults.length > 0 && (
          <div className='dropDown' ref={dropdownRef}>
            {filteredResults.slice(0, 7).map((item, index) => (
              <div
                key={index}
                style={{
                  padding: '15px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: hoveredIndex === index ? '#111' : '#fff', // Change background color on hover
                  color: hoveredIndex === index ? '#fff' : '#111', // Change text color on hover
                  borderTopLeftRadius: index === 0 ? '12px' : '0', // Add top left radius to the first item
                  borderTopRightRadius: index === 0 ? '12px' : '0', // Add top right radius to the first item
                  borderBottomLeftRadius: (filteredResults.length > 7 && index === 6) ? '0' : (index === filteredResults.slice(0, 7).length - 1 ? '12px' : '0'), // Remove bottom left radius if there is an "and more..." otherwise add to last visible
                  borderBottomRightRadius: (filteredResults.length > 7 && index === 6) ? '0' : (index === filteredResults.slice(0, 7).length - 1 ? '12px' : '0'), // Remove bottom right radius if there is an "and more..." otherwise add to last visible
                }}
                onMouseEnter={() => setHoveredIndex(index)} // Set hover state on mouse enter
                onClick={() => handleSearchDropdownItemClick(item)}
              >
                {item.name}
              </div>
            ))}
            {filteredResults.length > 7 && (
              <div
                style={{
                  padding: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#000',
                  borderTopLeftRadius: '0',
                  borderTopRightRadius: '0',
                  borderBottomLeftRadius: '12px',
                  borderBottomRightRadius: '12px', // Add rounded corners to the bottom
                  backgroundColor: '#fff', // Neutral background
                  cursor: 'default', // Default cursor to indicate non-interactivity
                }}
              >
                und weitere...
              </div>
            )}
          </div>
        )}


      </div>


      <div style={{ flex: '1', display: 'flex', justifyContent: 'left', alignContent: 'center' }}>
        <button onClick={handleSearchClick} className="buttonSearch">
          <img src="images/search.png" alt="Search" />
        </button>
      </div>

    </div>


  )
};

export default Search;