
import React from 'react';




const Ranking = ({ fullDetailUniversities, showOnlyVerified, toggleUniversityDetails, isMobile }) => {
  return (


    <div style={{ width: '100%' }}>


      {fullDetailUniversities.slice(0, 100).map((uni, index) => (

        <div key={index} onClick={() => toggleUniversityDetails(index)}>

          {uni.showDetails ? (

            <div className='uniItem_1_1'>


              <div style={{ alignContent: 'top', gridColumn: '1', display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row', alignItems: 'flex-start' }}>
                <div style={{ height: '100%', flex: 3, minWidth: '60%', display: 'flex', width: '100%', flexDirection: 'column', alignContent: 'space-between' }}>

                  {isMobile ? (
                    <div className='centeredVertically' style={{ minWidth: '60%', display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }}>


                      <div className='centeredVertically' style={{ display: 'flex', justifyContent: 'left', flexDirection: 'row' }}>

                        <h4 style={{ marginRight: '15px', whiteSpace: 'nowrap' }}># {index + 1}</h4>
                        <p className='badge' style={{ marginRight: '15px' }}>
                          {uni.averageRating.toFixed(2)}
                        </p>
                        <h3 style={{ marginRight: '15px' }}>{uni.name}</h3>


                      </div>


                      <img
                        src="/images/minus.png"
                        alt="Collapse"
                        style={{
                          width: '20px',
                          height: '20px',
                        }}
                      />


                    </div>

                  ) : (
                    <div className='centeredVertically' style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }}>
                      <div className='centeredVertically' style={{ display: 'flex', justifyContent: 'left', width: '100%', flexDirection: 'row' }}>

                        <h4 style={{ marginRight: '30px', whiteSpace: 'nowrap' }}># {index + 1}</h4>
                        <p className='badge' style={{ marginRight: '30px' }}>
                          {uni.averageRating.toFixed(2)}
                        </p>
                        <h3 style={{ marginRight: '30px' }}>{uni.name}</h3>

                        
                            {showOnlyVerified ? (
                              <p className='reviewCount' style={{ whiteSpace: 'nowrap', marginRight: '30px' }}>{Math.round(uni.verifiedCount / 7)} {Math.round(uni.verifiedCount / 7) < 2 ? "verifizierte Bewertung" : "verifizierte Bewertungen"}</p>
                            ) : (
                              <p className='reviewCount' style={{ whiteSpace: 'nowrap', marginRight: '30px' }}>{Math.round(uni.verifiedCount / 7) + Math.round(uni.nonVerifiedCount / 7)} {(Math.round(uni.verifiedCount / 7) + Math.round(uni.nonVerifiedCount / 7)) < 2 ? "Bewertung" : "Bewertungen"}</p>
                            )
                            }
                        


                      </div>

                      <img
                        src="/images/minus.png"
                        alt="Collapse"
                        className="centered-icon"
                        style={{ width: '20px', height: '20px' }}
                      />
                    </div>

                  )}





                </div>
              </div>


              <div className='uniItemDetails'>
                {uni.categories && uni.categories
                .slice() 
                .sort((a, b) => a.position - b.position) 
                .map((category, catIndex) => (
                  <div className='centered' key={catIndex} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px', width: '100%', margin: '4px', padding: '0' }}>
                    <p style={{ margin: '0', textAlign: 'right' }}>{category.category}</p>

                    <div style={{
                    }}>

                      <div className="progress-container" style={{
                        width: '100%',
                        height: '8px',
                        margin: '0',
                        padding: '0',
                        backgroundColor: '#fff', // White background for the non-progressed part
                        outline: '3px solid #222', // Black border
                        borderRadius: '10px' // Optional: Rounded corners for the whole bar
                      }}>
                        <div className="progress-bar" style={{
                          width: `${category.averageRating ? category.averageRating * 1 : 0}%`,
                          height: '100%',
                          margin: '0',
                          padding: '0',
                          backgroundColor: '#222', // Black fill for the progressed part
                          borderRadius: category.averageRating >= 99 ? '0px' : '0px 0 0 0px', // Inherit the border radius from the container
                        }}>
                        </div>
                      </div>
                    </div>

                  </div>
                ))}
              </div>



              {isMobile && (
                <div name='voteCount' style={{ display: 'flex', justifyContent: 'right', marginTop: 'auto' }}>
                  {showOnlyVerified ? (
                    <p className='reviewCount'>{Math.round(uni.verifiedCount / 7)} {Math.round(uni.verifiedCount / 7) < 2 ? "verifizierte Bewertung" : "verifizierte Bewertungen"}</p>
                  ) : (
                    <p className='reviewCount'>{Math.round(uni.verifiedCount / 7) + Math.round(uni.nonVerifiedCount / 7)} {(Math.round(uni.verifiedCount / 7) + Math.round(uni.nonVerifiedCount / 7)) < 2 ? "Bewertung" : "Bewertung"}</p>
                  )
                  }
                </div>
              )}


              {/* {!isMobile && (


                <div className="container-relative2" style={{  gridColumn: '3' }}>
                  <p className='badge hidden-badge'>
                    {uni.averageRating.toFixed(2)}
                  </p>
                  <img
                    src="/images/minus.png"
                    alt="Collapse"
                    className="centered-icon"
                    style={{ width: '20px', height: '20px' }}
                  />
                </div>

              )} */}


            </div>
          ) : (


            <div className='uniItem_1_2'>
              {isMobile ? (

                <div className='centeredVertically' style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'column' }}>

                  <div className='centeredVertically' style={{ flex: 3,  display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }}>

                    <div className='centeredVertically' style={{ display: 'flex', justifyContent: 'left', flexDirection: 'row' }}>
                      <h4 style={{ marginRight: '15px', whiteSpace: 'nowrap' }}># {index + 1}</h4>
                      <p className='badge' style={{ marginRight: '15px' }}>
                        {uni.averageRating.toFixed(2)}
                      </p>
                      <h3 style={{ marginRight: '15px' }}>{uni.name}</h3>
                    </div>

                    <img
                      src="/images/plus.png"
                      alt="Expand"
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                    />

                  </div>

                  <div style={{ flex: 1, display: 'flex', justifyContent: 'right', width: '100%' }}>
                    {showOnlyVerified ? (
                      <p className='reviewCount' >{Math.round(uni.verifiedCount / 7)} {Math.round(uni.verifiedCount / 7) < 2 ? "verifizierte Bewertung" : "verifizierte Bewertungen"}</p>
                    ) : (
                      <p className='reviewCount'>{Math.round(uni.verifiedCount / 7) + Math.round(uni.nonVerifiedCount / 7)} {(Math.round(uni.verifiedCount / 7) + Math.round(uni.nonVerifiedCount / 7)) < 2 ? "Bewertung" : "Bewertungen"}</p>
                    )
                    }

                  </div>

                </div>


              ) : (
                <div className='centeredVertically' style={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }}>

                  <div className='centeredVertically' style={{ display: 'flex', justifyContent: 'left', width: '100%', flexDirection: 'row' }}>

                    <h4 style={{ marginRight: '30px', whiteSpace: 'nowrap' }}># {index + 1}</h4>
                    <p className='badge' style={{ marginRight: '30px' }}>
                      {uni.averageRating.toFixed(2)}
                    </p>
                    <h3 style={{ marginRight: '30px', width:'auto' }}>{uni.name}</h3>

                    {showOnlyVerified ? (
                      <p className='reviewCount' style={{ whiteSpace: 'nowrap', marginRight: '30px' }} >{Math.round(uni.verifiedCount / 7)} {Math.round(uni.verifiedCount / 7) < 2 ? "verifizierte Bewertung" : "verifizierte Bewertungen"}</p>
                    ) : (
                      <p className='reviewCount' style={{ whiteSpace: 'nowrap', marginRight: '30px' }}>{Math.round(uni.verifiedCount / 7) + Math.round(uni.nonVerifiedCount / 7)} {(Math.round(uni.verifiedCount / 7) + Math.round(uni.nonVerifiedCount / 7)) < 2 ? "Bewertung" : "Bewertungen"}</p>
                    )
                    }

                  </div>

                  <div style={{ flex: 1, display: 'flex', justifyContent: 'right' }}>


                    <img
                      src="/images/plus.png"
                      alt="Expand"
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                    />


                  </div>

                </div>
              )}

            </div>


          )


          }

        </div>

      ))}




    </div>

  )
};

export default Ranking;














{/* <button ref={infoButtonRef1} onClick={(e) => {
                                e.stopPropagation();
                                toggleVoteRatioDetails(index);
                                
                              }} className="buttonInfo">
                                    <img src="images/info.png" alt="Info" style={{ maxWidth: '10px', maxHeight: '10px' }} />
                            </button>  */}

{/* {visibleInfoBoxIndex === index && (
                            <div ref={infoRef} style={{
                                position: 'absolute',
                                top: '20px', // Adjust as needed
                                right: '7%', // Position to the right of the button
                                backgroundColor: 'white',
                                padding: '10px 20px',
                                borderRadius: '10px',
                                width: '150px', // Adjust as needed
                                height: '100px', // Adjust as needed
                                // transform: 'translateY(-50%)', // Center vertically relative to the button
                                zIndex: 100,
                                boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.2)', // Drop shadow
                                border: '3px solid #111', // Border
                              }}>

                              <p>Some additional information...</p>
                            </div>
                            )} */}





{/* <div style={{display: 'flex', flexDirection:'column', alignItems: 'left', margin: '10px',  marginTop: '30px', width:'100%' }}>
                    {uni.categories && uni.categories.map((category, catIndex) => (
                      <div className='centered' key={catIndex} style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gap: '20px', width:'50%', margin:'4px', padding:'0' }}>
                        <p style={{ margin: '0', textAlign: 'right' }}>{category.category}</p>

                        <div className="progress-container" style={{ 
                              width: '100%', 
                              height: '8px', 
                              margin: '0', 
                              padding: '0',
                              backgroundColor: '#fff', // White background for the non-progressed part
                              outline: '3px solid #222', // Black border
                              borderRadius: '10px' // Optional: Rounded corners for the whole bar
                            }}>
                            <div className="progress-bar" style={{ 
                                width: `${category.averageRating ? category.averageRating * 1 : 0}%`, 
                                height: '100%', 
                                margin: '0', 
                                padding: '0',
                                backgroundColor: '#222', // Black fill for the progressed part
                                borderRadius: category.averageRating >= 99 ? '0px' : '0px 0 0 0px', // Inherit the border radius from the container
                              }}>
                            </div>
                          </div>
                      </div>
                    ))}
                </div> */}






{/* <button ref={infoButtonRef1} onClick={(e) => {
                            e.stopPropagation();
                            toggleVoteRatioDetails(index);
                            
                          }} className="buttonInfo">
                                <img src="images/info.png" alt="Info" style={{ maxWidth: '10px', maxHeight: '10px' }} />
                        </button>  */}

{/* {visibleInfoBoxIndex === index && (
                        <div ref={infoRef} style={{
                            position: 'absolute',
                            top: '20px', // Adjust as needed
                            right: '7%', // Position to the right of the button
                            backgroundColor: 'white',
                            padding: '10px 20px',
                            borderRadius: '10px',
                            width: '150px', // Adjust as needed
                            height: '100px', // Adjust as needed
                            // transform: 'translateY(-50%)', // Center vertically relative to the button
                            zIndex: 100,
                            boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.2)', // Drop shadow
                            border: '3px solid #111', // Border
                          }}>

                          <p>Some additional information...</p>
                        </div> */}